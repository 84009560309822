exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourservices-js": () => import("./../../../src/pages/ourservices.js" /* webpackChunkName: "component---src-pages-ourservices-js" */),
  "component---src-pages-requestquote-js": () => import("./../../../src/pages/requestquote.js" /* webpackChunkName: "component---src-pages-requestquote-js" */),
  "component---src-pages-strapi-page-slug-js": () => import("./../../../src/pages/{strapiPage.slug}.js" /* webpackChunkName: "component---src-pages-strapi-page-slug-js" */),
  "component---src-pages-strapi-post-slug-js": () => import("./../../../src/pages/{strapiPost.slug}.js" /* webpackChunkName: "component---src-pages-strapi-post-slug-js" */)
}

